import React, { useState } from "react";
import styles from "./Gallery.module.scss";
import useFetchData from "../../utils/FetchData";
import { useLanguage } from "../../context/Language";
import {
  getGalleryHigjienike,
  getGalleryKozmetike,
  getGalleryUshqimore,
} from "../../utils/api";

const Gallery = () => {
  const { currentLanguage } = useLanguage();
  const { data: ushqimore } = useFetchData(getGalleryUshqimore);
  const { data: higjienike } = useFetchData(getGalleryHigjienike);
  const { data: kozmetike } = useFetchData(getGalleryKozmetike);
  const [selectedImage, setSelectedImage] = useState(-1);

  const groupByBrand = (items) => {
    const grouped = {};
    items?.forEach((item) => {
      item.gallery_brands?.forEach((brand) => {
        if (!grouped[brand.name]) grouped[brand.name] = [];
        grouped[brand.name].push(item);
      });
    });
    return grouped;
  };

  const renderGallery = (groupedImages) => (
    <>
      {Object.entries(groupedImages).map(([brandName, images]) => (
        <div key={brandName}>
          <h4 className={styles.h4}>{brandName}</h4>
          <gallery style={{ marginBottom: 45 }}>
            {images.map((img) => (
              <GalleryImg
                imageSrc={img.image.url}
                key={img.id}
                index={img.id}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            ))}
          </gallery>
        </div>
      ))}
    </>
  );
  return (
    <galleryWrapper>
      <textContent>
        <acc>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {currentLanguage === "en" ? "Gallery" : "Galeria"}
        </acc>
      </textContent>
      <h2 className={styles.h2}>{currentLanguage === "en" ? "Food" : "Ushqimore"}</h2>
      {renderGallery(groupByBrand(ushqimore?.data))}
      <h2 className={styles.h2}>{currentLanguage === "en" ? "Hygiene" : "Higjienike"}</h2>
      {renderGallery(groupByBrand(higjienike?.data))}
      <h2 className={styles.h2}>{currentLanguage === "en" ? "Cosmetics" : "Kozmetike"}</h2>
      {renderGallery(groupByBrand(kozmetike?.data))}
    </galleryWrapper>
  );
};

export default Gallery;

const GalleryImg = ({ imageSrc, index, selectedImage, setSelectedImage }) => {
  return (
    <>
      <imageWrapper
        onClick={() => {
          if (index !== selectedImage) {
            setSelectedImage(index);
          }
        }}
      >
        <img src={imageSrc} alt="" />
      </imageWrapper>
      <screenImgCover
        style={{
          opacity: index !== selectedImage ? "0" : "1",
          pointerEvents: index === selectedImage ? "unset" : "none",
        }}
        onClick={() => {
          if (index === selectedImage) {
            setSelectedImage(-1);
          }
        }}
      >
        <img src={imageSrc} alt="" />
        <background />
      </screenImgCover>
    </>
  );
};
